<template>
  <div>
    <!-- START OF GRADIENT JUMBOTRON -->
    <section class="gradient-jumbotron">
      <div>
        <h1>Our Solutions</h1>
      </div>
    </section>
    <!-- END OF GRADIENT JUMBOTRON -->

    <!-- START OF SUBPAGE CONTENT -->
    <div class="subpage-content">
      <div class="container solutions-spacing">
        <!-- START OF TWO IN A ROW -->
        <div class="row solutions-spacing">
          <div class="col-md-6">
            <div class="solutions-content-side">
              <img
                src="../assets/images/icon-crm-system.png"
                class="icon"
              >
              <h2>Customer Relationship Management (CRM) System</h2>
              <hr>
              <p>
                Good customer relationships are built on trust and best practices. In
                fact, a customized mobile app or website can serve as a direct marketing
                channel, allowing you to connect with your customers. Not only does it
                enable you to send personalized updates about your business to a wide
                customer base, you can also receive feedback. Therefore, it will further
                improve your customer relations.
              </p>
              <p>
                Our CRM is specially customized for the gaming and e-commerce industries,
                and we will help you understand your users deeply.
              </p>
              <p>
                From the moment they enter your website, we will build a user profile of
                them, and we will tell you the right time to interact with them and
                increase your sales.
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="solutions-content-side">
              <img
                src="../assets/images/icon-ui-design.png"
                class="icon"
              >
              <h2>Web Application</h2>
              <hr>
              <p>
                We provide custom backend development for web applications of any
                complexity that store, process and manage data from disparate sources. Our
                backend developers are proficient at building systems that can sustain
                high loads while remaining stable.
              </p>
            </div>
          </div>
        </div>

        <div class="row solutions-spacing">
          <div class="col-md-6">
            <div class="solutions-content-side">
              <img
                src="../assets/images/icon-ios-development.png"
                class="icon"
              >
              <h2>iOS Development</h2>
              <hr>
              <p>
                We at TextEdge specialize in designing the next generation of end-to-end
                mobile applications to meet your most demanding requirements. We build
                secure, high-performance, and engaging iPhone apps for the entire Apple
                product range, and user-friendly interfaces for businesses in all fields.
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="solutions-content-side">
              <img
                src="../assets/images/icon-android-development.png"
                class="icon"
              >
              <h2>Android Development</h2>
              <hr>
              <p>
                Our Android app development team can address a variety of compatibility
                issues, including device and OS versions, to build competitive mobile
                apps.
              </p>
            </div>
          </div>
        </div>

        <div class="row solutions-spacing">
          <div class="col-md-6">
            <div class="solutions-content-side">
              <img
                src="../assets/images/icon-ui-design.png"
                class="icon"
              >
              <h2>User Interface Design</h2>
              <hr>
              <p>
                After understanding your needs, challenges, goals and collection
                requirements, our team will help you design and create an interface for
                your website, e-commerce portal, mobile app, product or platform to
                showcase your brand identity and uniqueness.
              </p>
            </div>
          </div>
        </div>

        <div class="gap">
&nbsp;
        </div>
        <hr class="solutions-divider">
        <h2 class="solutions-header">
          Technologies
        </h2>

        <!-- <div class="slick slick_box" class="multiple-imgs solutions-imgs"> -->
        <div class="multiple-imgs solutions-imgs">
          <VueSlickCarousel v-bind="settings">
            <div
              v-for="(item, index) in imagesList"
              :key="index"
              class="spacing"
            >
              <div class="display">
                <img
                  :src="item.imgUrl"
                  class="img"
                >
                <h3>{{ item.name }}</h3>
              </div>
            </div>
          </VueSlickCarousel>
        </div>

        <!-- END OF MULTIPLE IMGS SLIDER -->
      </div>
    </div>
    <!-- END OF SUBPAGE CONTENT -->
  </div>
</template>

<script>
import $ from "jquery";
import con1 from "@/assets/images/go-logo.png";
import con2 from "@/assets/images/react-logo.jpg";
import con3 from "@/assets/images/ui-img.jpg";
import con4 from "@/assets/images/crm-img2.jpg";
import con5 from "@/assets/images/ios-apple-logo.jpg";
import con6 from "@/assets/images/android-logo.jpg";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import Swiper from "swiper";
// import 'swiper/css/swiper.css'
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        infinite: true,
        focusOnSelect: true,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        autoplay: true,
        fade: false, // 开启渐隐切换模式
        autoplaySpeed: 2000, //自动播放速度
        arrows: true,
        dots: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      imagesList: [
        {
          name: "Back End",
          imgUrl: con1,
        },
        {
          name: "Front End",
          imgUrl: con2,
        },
        {
          name: "UI",
          imgUrl: con3,
        },
        {
          name: "CRM System",
          imgUrl: con4,
        },
        {
          name: "iOS",
          imgUrl: con5,
        },
        {
          name: "Android",
          imgUrl: con6,
        },
      ],
    };
  },
  computed: {
    isMobileDevice() {
      const ua = navigator.userAgent.toLowerCase();
      const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        ua
      );
      const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
      return t1 || t2;
    },
  },
  mounted() {
    if (this.isMobileDevice) {
      this.settings.arrows = false;
    }
    this.$nextTick(() => {
      $(".Slick").slick({
        infinite: true, //循环功能
        slidesToShow: 3, //可见数
        slidesToScroll: 1, //滚动个数
        autoplay: true, //自动播放
        arrows: true, //左右箭头
        draggable: true, //拖拽功能
        dots: true, //指示点
        fade: false, // 开启渐隐切换模式
        autoplaySpeed: 5000, //自动播放速度
        speed: 1500, //切换动画速度
      });
    });
  },
};
</script>

<style scoped>
.slick_box .slick-list {
  /* margin-left: 40px !important; */
}
.slick-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display {
  border: 1px solid #ae8a55;
  border-radius: 5px;
  margin-top: 25px;
}

.slick-slider {
  display: flex;
  align-items: center;
}

.slick-item {
  max-width: 400px;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  margin-left: 15px !important;
  padding: 60px;
}

.slick-item .slick-item-img {
  /* width: 299px;
  height: 247px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center; */
}

.arrow-btn {
  border: none;
  img {
    height: 40px;
  }
}
:deep(.slick-dots) {
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  /* bottom: -85px; */
  display: flex !important;
  justify-content: center;
}
:deep(.slick-dots li:marker) {
  display: none;
}
:deep(.slick-dots li button:before) {
  color: #ae8a55;
}
:deep(.slick-prev:before) {
  content: "\F12E";
  font-family: "bootstrap-icons";
  line-height: 1;
  opacity: 1;
  color: #ae8a55;
  font-size: 26px;
}
:deep(.slick-prev:hover:before) {
  content: "\F12D";
}
:deep(.slick-next:before) {
  content: "\F137";
  font-family: "bootstrap-icons";
  line-height: 1;
  opacity: 1;
  color: #ae8a55;
  font-size: 26px;
}
:deep(.slick-next:hover:before) {
  content: "\F136";
}
</style>
