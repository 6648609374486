<template>
  <div>
    <!-- START OF GRADIENT JUMBOTRON -->
    <section class="gradient-jumbotron">
      <div>
        <h1>Contact Us</h1>
      </div>
    </section>
    <!-- END OF GRADIENT JUMBOTRON -->

    <!-- START OF SUBPAGE CONTENT -->
    <section class="subpage-content">
      <!-- GOOGLE MAP -->
      <!-- <iframe
        src="https://maps.app.goo.gl/5wzwMmA7GnPuLE37A?output=embed"
        width="100%"
        height="200"
        frameborder="0"
        style="border: 0"
        allowfullscreen
      ></iframe> -->
      <AddressMap />

      <!-- START OF CONTACT US CONTENT -->
      <div class="container">
        <div class="contact-info">
          <div class="row">
            <h2>Get In Touch With Us!</h2>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="indv">
                    <i class="bi bi-geo-alt" />
                    <!-- <span class="address">110 Middle Road, Singapore 188968</span> -->
                    <span 
                      class="address"
                    ><a
                      @click="jumpMap"
                    >6/F A-1, B and P Building, 843 Arnaiz Avenue, Legaspi Village, San Lorenzo, Makati City, Manila, Philippines</a></span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="indv">
                    <i class="bi bi-envelope" />
                    <span 
                      class="email"
                    ><a href="mailto:info@textedge.net">info@textedge.net</a></span>
                  </div>
                </div>
                <div 
                  v-show="false" 
                  class="col-md-12"
                >
                  <div class="indv">
                    <i class="bi bi-telephone" />
                    <span class="phone">+65 9782 6016</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-7">
              <div class="form-container">
                <div>
                  <i class="bi bi-envelope-check"></i>
                  Your message has been sent. Thank you!
                </div>

                <div id="errormessage">
                  <i class="bi bi-envelope-exclamation"></i>
                  Error occured, Please try again
                </div>

                <form class="contactForm">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        v-model="form.name"
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 characters"
                        @change="inputBlur(form.name, 'name')"
                      />
                      <div class="validation" v-show="nameError">
                        Please enter at least 4 characters
                      </div>
                    </div>

                    <div class="col-md-6 form-group">
                      <input
                        v-model="form.email"
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                        @change="inputBlur(form.email, 'email')"
                      />
                      <div class="validation" v-show="emailError">
                        Please enter a valid email
                      </div>
                    </div>

                    <div class="col-md-12 form-group">
                      <input
                        v-model="form.subject"
                        type="text"
                        class="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        data-rule="minlen:6"
                        data-msg="Please enter at least 8 characters"
                        @change="inputBlur(form.subject, 'subject')"
                      />
                      <div class="validation" v-show="subjectError">
                        Please enter at least 8 characters
                      </div>
                    </div>

                    <div class="col-md-12 form-group">
                      <textarea
                        v-model="form.message"
                        class="form-control"
                        name="message"
                        rows="5"
                        data-rule="required"
                        data-msg="Please enter your message"
                        placeholder="Message"
                        @change="inputBlur(form.message, 'message')"
                      ></textarea>
                      <div class="validation" v-show="messageError">
                        Please enter your message
                      </div>
                      <div class="text-center">
                        <div
                          class="g-recaptcha"
                          data-sitekey="6LeQsPIlAAAAAGP_m6RnJttS_m_9t2dI0T32xP6i"
                        ></div>

                        <div id="errormessage-c">Please validate reCAPTCHA</div>

                        <div class="btn">
                          <a @click="handleClickSubmit('aboutUs')"
                            >Send message <i class="bi bi-arrow-right"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- END OF CONTACT US CONTENT -->
    </section>
    <!-- END OF SUBPAGE CONTENT -->
  </div>
</template>

<script>
// import Map from "../components/map";
import AddressMap from "../components/addressMap";
import addressMap from "../mixins/jumpMap.js";
export default {
  components: { AddressMap },
  mixins: [addressMap],
  data() {
    return {
      nameError: false,
      emailError: false,
      subjectError: false,
      messageError: false,
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },

  methods: {
    handleClickSubmit() {
      for (const key in this.form) {
        if (!this.form[key]) {
          this[`${key}Error`] = true;
        }
      }
    },
    inputBlur(text, key) {
      if (text) {
        this[`${key}Error`] = false;
      }
    },
  },
};
</script>

<style></style>
