<template>
  <div>
    <!-- START OF GRADIENT JUMBOTRON -->
    <section class="gradient-jumbotron">
      <div>
        <h1>Join Us</h1>
      </div>
    </section>
    <!-- END OF GRADIENT JUMBOTRON -->

    <!-- START OF SUBPAGE CONTENT -->
    <div class="subpage-content">
      <div class="container">
        <!-- START OF JOIN US INTRO -->
        <div class="joinus-intro">
          <img
            src="../assets/images/icon-hiring.png"
            class="icon"
          >
          <h2>We are hiring!</h2>
          <p>
            If you are passionate about technologies, take pride in resolving challenging
            problems and delivering top-notch service to clients, then take a look at our
            open positions.
          </p>
        </div>
        <!-- END OF JOIN US INTRO -->

        <!-- START OF EMPLOYEE BENEFITS CONTENT -->
        <div class="benefits-content">
          <img
            src="../assets/images/icon-benefits.png"
            class="icon"
          >
          <h2>Employee Benefits</h2>
          <ul>
            <li>AWS</li>
            <li>Progressive Annual Leaves</li>
            <li>Medical, Dental, Term Life Insurance</li>
            <li>Team Bonding Event</li>
          </ul>
        </div>
        <!-- END OF EMPLOYEE BENEFITS CONTENT -->

        <h2 class="joinus-header">
          Available Positions:
        </h2>

        <!-- START OF ACCORDIONS -->
        <div
          id="jobListingAccordion"
          class="accordion"
        >
          <!-- START OF ACCORDION - JOB LISTING 1 -->
          <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Java Developer
            </button>
          </h2>

          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#jobListingAccordion">
            <div class="accordion-body">
              <p>Local software house led by IT-savvy management looking to expand our talent pool of Java Developers to work on upcoming projects for our clients. As the focus is on backend systems and applications, you will be expected to build APIs and tools applicable to the Microservice architecture.</p> -->

          <!-- START OF SHORT DESCRIPTION -->
          <!-- <div class="short-desc"> 
                <img src="../assets/images/icon-joinus.png" class="icon"/>
                <div class="right-text">
                  <p>We offer a competitive salary with 13th-month pay and year-end bonus based on the company and individual performance. Fresh graduates are welcome to apply.</p>
                </div>
              </div> -->
          <!-- END OF SHORT DESCRIPTION -->

          <!-- <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-description.png" class="header-icon"/>
                  Description
                </h3>
                <hr/>
                <ul> 
                  <li>Analyse and model (using UML) the user requirements, use cases and processes to uncover potential issues.</li>
                  <li>Adopt industry best practices and standards</li>
                  <li>Communicate and collaborate with team members in fact-finding and solutioning process</li>
                  <li>Design and develop scalable and holistic solutions</li>
                  <li>Troubleshoot and maintain existing and/or legacy applications</li>
                  <li>Familiarise and utilise implemented DevOps tools to automate building and deployment</li>
                  <li>Perform testing and documentation</li>
                  <li>Presentation/demonstration of completed tasks or issues to key decision-makers</li>
                  <li>Able to perform in Agil /Scrum environment</li>
                </ul>
              </div>

              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-qualification.png" class="header-icon"/>
                  Expected Qualifications & Experience
                </h3>
                <hr/>
                <ul>
                  <li><b>Bachelor's Degree in Computer Science or equivalent</b></li>
                  <li><b>Basic Knowledge and experience</b></li>
                  <li>Good understanding of Java OOAD and Design Patterns</li>
                  <li>Java Backend development knowledge and code optimization</li>
                  <li>Multithreading, and Logging</li>
                  <li>JSON</li>
                  <li>SQL and relational databases (Oracle, MS SQL Server, MySQL, etc.)</li>
                  <li>UML modelling</li>
                  <br/>
                  <h4>Optional but preferred</h4>
                  <ul>
                    <li>Knowledge/experience with Microservices architecture</li>
                    <li>Knowledge/experience with Aspect Oriented Programming</li>
                    <li>Knowledge/experience with building RESTful APIs with Java backend frameworks (Spring / Spring Boot / Spring Cloud)</li>
                    <li>Knowledge/experience with Message Queue (Kafka), and Caching (Redis) is preferred</li>
                    <li>Knowledge/experience with Source Code Control tools such as Git and Maven</li>
                    <li>1 or more completed SDLC</li>
                  </ul> 
                </ul>
              </div>

              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-job-type.png" class="header-icon"/>
                  Job Type/Salary
                </h3>
                <hr/>
                <ul>
                  <li>Full-time</li>
                  <li>Permanent</li>
                  <li>4.5k to 6k</li>
                </ul>
              </div>
 
              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-experience-sub.png" class="header-icon" />
                  Experience
                </h3>
                <hr/> 
                <p>1 to 3 ~ years</p>
              </div>

              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-education.png" class="header-icon"/>
                  Education
                </h3> 
                <hr/>
                <p>Bachelor's Degree in Computer Science or equivalent</p>
              </div>
              
              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-location.png" class="header-icon"/>
                  Location
                </h3> 
                <hr/>
                <p>Town Area, Singapore</p>
              </div>

              <p>If you meet the above qualifications and are passionate about joining us, we would love to hear from you. Please apply with your resume and a cover letter explaining why you are the right candidate for this position.</p> -->

          <!-- APPLY NOW BUTTON -->
          <!-- <div class="apply">
                <a href="#" target="_blank">
                  <div class="apply-btn">
                    Apply Now!
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div> -->
          <!-- END OF ACCORDION - JOB LISTING 1 -->

          <!-- START OF ACCORDION - JOB LISTING 2 -->
          <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Mid Level Java Developer
            </button>
          </h2>

          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#jobListingAccordion">
            <div class="accordion-body">
              <p>We are a small local software house founded in 2020 by experienced IT-savvy veterans. We are looking to expand our talent pool of Java Developers to work on upcoming projects for our clients and in future our own products.</p> -->

          <!-- START OF SHORT DESCRIPTION -->
          <!-- <div class="short-desc"> 
                <img src="../assets/images/icon-joinus.png" class="icon"/>
                <div class="right-text">
                  <p>We offer a competitive salary with 13th month pay and year-end bonus based on the company and individual performance.</p>
                </div>
              </div> -->
          <!-- END OF SHORT DESCRIPTION -->

          <!-- <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-description.png" class="header-icon"/>
                  Description
                </h3>
                <hr/>
                <ul> 
                  <li>Analyse and model (using UML) the user requirements, use cases and processes.</li>
                  <li>Communicate and collaborate with team members</li>
                  <li>Design and develop scale-able and holistic solutions</li>
                  <li>Analyse, troubleshoot and maintain existing and/or legacy applications</li>
                  <li>Optimise code inefficiencies, perform testing and prepare documentation</li>
                  <li>Demonstrate completed features, proposals and findings to key decision-makers</li>
                  <li>Guide juniors developers in technical know-how</li>
                </ul>
              </div>

              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-qualification.png" class="header-icon"/>
                  Expected Qualifications & Experience
                </h3>
                <hr/>
                <ul>
                  <li>Bachelor's Degree in Computer Science or equivalent</li>
                  <li>Takes initiative and is able to communicate effectively in English</li>
                  <li>Good understanding of Java OOAD and Design Patterns</li>
                  <li>Experienced in building RESTful API and Microservices using Spring Boot</li>
                  <li>UML modelling, SQL and relational databases (Oracle, MS SQL Server, MySQL, etc.)</li>

                  <br/>
                  <h4>Optional but preferred</h4>
                  <ul>
                    <li>Knowledge/experience with DevOps tools such as Jira, Git, Jenkins etc</li>
                    <li>Mandarin to communicate with Chinese clients.</li>
                    <li>3 or more completed SDLC</li>
                  </ul> 
                </ul>
              </div>

              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-job-type.png" class="header-icon"/>
                  Job Type/Salary
                </h3>
                <hr/>
                <ul>
                  <li>Full-time</li>
                  <li>Permanent</li>
                  <li>6k to 8k</li>
                </ul>
              </div>
 
              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-experience-sub.png" class="header-icon" />
                  Experience
                </h3>
                <hr/> 
                <p>5 - 9 years and above</p>
              </div>

              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-education.png" class="header-icon"/>
                  Education
                </h3> 
                <hr/>
                <p>Bachelor's Degree in Computer Science or equivalent</p>
              </div>
              
              <div class="listing-info">
                <h3>
                  <img src="../assets/images/icon-location.png" class="header-icon"/>
                  Location
                </h3> 
                <hr/>
                <p>Town Area, Singapore</p>
              </div>

              <p>If you meet the above qualifications and are passionate about joining us, we would love to hear from you. Please apply with your resume and a cover letter explaining why you are the right candidate for this position.</p> -->

          <!-- APPLY NOW BUTTON -->
          <!-- <div class="apply">
                <a href="#" target="_blank">
                  <div class="apply-btn">
                    Apply Now!
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div> -->
          <!-- END OF ACCORDION - JOB LISTING 2 -->

          <!-- START OF ACCORDION - JOB LISTING 3 -->
          <div class="accordion-item">
            <h2
              id="headingThree"
              class="accordion-header"
            >
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                :class="!infrastructureType ? 'collapsed' : ''"
                @click="handleClick('infrastructureType')"
              >
                Developer (Golang/C/C++/JAVA/Python)
              </button>
            </h2>

            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#jobListingAccordion"
              :class="infrastructureType ? 'show' : ''"
            >
              <div class="accordion-body">
                <!-- <p>
                  We are seeking a highly skilled and motivated Infra Engineer to join our
                  dynamic team. As part of the DevOps & Infra team, you will have a
                  comprehensive scope of responsibilities, including managing not only the
                  IT infrastructure but also end-user devices and services. Your role will
                  encompass ensuring the smooth and secure functioning of end-user
                  laptops, managing user administration and access rights, and maintaining
                  the stability and performance of Wi-Fi devices.
                </p>
                <p>
                  In collaboration with Development, Operations, and DevOps teams, you
                  will work towards enhancing the availability, scalability, and security
                  of our applications, network, and end-user systems. Your expertise will
                  contribute to maintaining a robust IT environment, optimizing end-user
                  experience, and facilitating efficient release cycles and continuous
                  integration and delivery of software.
                </p> -->

                <!-- START OF SHORT DESCRIPTION -->
                <!-- <div class="short-desc">
                  <img src="../assets/images/icon-joinus.png" class="icon" />
                  <div class="right-text">
                    <p>
                      We offer a competitive salary with 13th month pay and year-end bonus
                      based on the company and individual performance.
                    </p>
                  </div>
                </div> -->
                <!-- END OF SHORT DESCRIPTION -->

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-description.png"
                      class="header-icon"
                    >
                    Description
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      Responsible for completing system design, interface design and data
                      modeling according to software requirements, and writing
                      corresponding technical documents;
                    </li>
                    <li>
                      Able to independently complete the core coding work and unit testing
                      of the corresponding system;
                    </li>
                    <li>
                      Ability to independently solve difficult problems encountered during
                      development;
                    </li>
                    <li>
                      Participate in system stability, performance and scalability
                      debugging;
                    </li>
                    <li>
                      Able to complete tasks and goals assigned by superiors on time and
                      in quantity;
                    </li>
                    <li>
                      Participate in/responsible for the functional design and development
                      of product system modules.
                    </li>
                  </ul>
                </div>

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-qualification.png"
                      class="header-icon"
                    >
                    Expected Qualifications & Experience
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      Computer or related majors, full-time undergraduate degree or above,
                      more than 2 years of practical work experience;
                    </li>
                    <li>
                      Proficient in Linux environment programming and Golang language
                      development;
                    </li>
                    <li>Familiar with common cache systems such as redis/memcache;</li>
                    <li>
                      Familiar with common container ecological chains such as docker and kubernetes;
                    </li>
                    <li>
                      Familiar with common load balancing and high availability systems.
                    </li>
                    <!-- <li>
                      Familiarity with configuration management tools (e.g., Ansible,
                      Puppet, Chef) is a plus.
                    </li>
                    <li>
                      Experience in configuring and managing Nginx web servers and
                      knowledge of load balancing, caching, and proxying concepts.
                    </li>
                    <li>
                      Knowledge of network security principles and experience with
                      firewalls like FortiGate and linux firewall.
                    </li>
                    <li>
                      Strong problem-solving skills and ability to work in a fast-paced,
                      collaborative environment.
                    </li>
                    <li>
                      Fluency in both Mandarin and English, with written and verbal
                      communication skills in both languages
                    </li> -->
                    <li>Business direction: TextEdge currently has demand for all product lines, and its business directions are rich, involving back-end, front-end, operation and maintenance, data analysis, data development, and algorithms.</li>
                  </ul>
                </div>

                <!-- <div class="listing-info">
                  <h3>
                    <img src="../assets/images/icon-job-type.png" class="header-icon" />
                    Job Type/Salary
                  </h3>
                  <hr />
                  <ul>
                    <li>Full-time</li>
                    <li>Permanent</li>
                    <li>3k to 6k</li>
                  </ul>
                </div>

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-experience-sub.png"
                      class="header-icon"
                    />
                    Experience
                  </h3>
                  <hr />
                  <p>3 years and above</p>
                </div>

                <div class="listing-info">
                  <h3>
                    <img src="../assets/images/icon-education.png" class="header-icon" />
                    Education
                  </h3>
                  <hr />
                  <p>
                    Diploma & above in Information Tech, Engineering, or a related field.
                  </p>
                </div>

                <div class="listing-info">
                  <h3>
                    <img src="../assets/images/icon-location.png" class="header-icon" />
                    Location
                  </h3>
                  <hr />
                  <p>Town Area, Singapore</p>
                </div>

                <p>
                  If you meet the above qualifications and are passionate about joining
                  us, we would love to hear from you. Please apply with your resume and a
                  cover letter explaining why you are the right candidate for this
                  position.
                </p> -->

                <!-- APPLY NOW BUTTON -->
                <div class="apply">
                  <a
                    href="https://www.mycareersfuture.gov.sg/job/information-technology/infrastructure-engineer-textedge-4e25bf9bcc2b692be564e87133d3d917"
                    target="_blank"
                  >
                    <div class="apply-btn">Apply Now!</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF ACCORDION - JOB LISTING 3 -->

          <!-- START OF ACCORDION - JOB LISTING 4 -->
          <div class="accordion-item">
            <h2
              id="headingFour"
              class="accordion-header"
            >
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                :class="!devOpsType ? 'collapsed' : ''"
                @click="handleClick('devOpsType')"
              >
                Javascript/Flutter Developer
              </button>
            </h2>

            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#jobListingAccordion"
              :class="devOpsType ? 'show' : ''"
            >
              <div class="accordion-body">
                <!-- <p>
                  We are seeking a highly skilled and motivated DevOps Engineer to join
                  our dynamic team. As a DevOps Engineer, you will be responsible for
                  implementing, and managing our software development, testing, and
                  deployment processes. You will collaborate closely with development,
                  operations, and infrastructure teams to streamline our software delivery
                  pipeline and ensure high availability, scalability, and security of our
                  applications. As part of the DevOps & Infra team, you are to ensure
                  smooth and efficient release cycles and continuous integration and
                  delivery of software.
                </p> -->

                <!-- START OF SHORT DESCRIPTION -->
                <!-- <div class="short-desc">
                  <img src="../assets/images/icon-joinus.png" class="icon" />
                  <div class="right-text">
                    <p>
                      We offer a competitive salary with 13th month pay and year-end bonus
                      based on the company and individual performance.
                    </p>
                  </div>
                </div> -->
                <!-- END OF SHORT DESCRIPTION -->

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-description.png"
                      class="header-icon"
                    >
                    Description
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      More than three years of web/mobile development experience;
                    </li>
                    <li>
                      Proficient in the hybrid development model of mobile terminals;
                    </li>
                    <li>
                      Use the flutter/Javascript technology stack to develop business functions and complete high-quality coding and testing;
                    </li>
                    <li>
                      Participate in the construction of flutter/Javascript development framework, the precipitation of business components and performance optimization;
                    </li>
                    <li>
                      Continue to follow up on the technical progress of the flutter/Javascript community and participate in the technical construction of the flutter/Javascript open source community;
                    </li>
                    <li>
                      Participate in the formulation of mobile specifications, and skillfully complete the preparation of technical and other related documents;
                    </li>
                    <li>
                      Experience in packaging and publishing Android and iOS is preferred.
                    </li>
                  </ul>
                </div>

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-qualification.png"
                      class="header-icon"
                    >
                    Expected Qualifications & Experience
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      Bachelor degree or above, computer or related major;
                    </li>
                    <li>
                      Three years or more of Android or iOS or Web development experience, with complete experience in commercial-grade mobile application or game development projects;
                    </li>
                    <li>
                      Have solid programming skills, good coding style, and strong ability to analyze and solve problems;
                    </li>
                    <li>
                      Familiar with the mechanisms and features of iOS or Android systems, and proficient in UI, network, XML/JSON parsing and other development technologies;
                    </li>
                    <li>
                      Be familiar with the flutter/Javascript framework and dart language, be proficient in using various flutter/Javascript layouts and animations, and have a certain understanding of JAVA or OC;
                    </li>
                    <li>
                      Positive and optimistic, strong sense of responsibility, serious and meticulous work, and good team communication and collaboration skills.
                    </li>
                    <li>Business direction: TextEdge currently has demand for all product lines, and its business directions are rich, involving back-end, front-end, operation and maintenance, data analysis, data development, and algorithms.</li>
                  </ul>
                </div>

                <!-- <div class="listing-info">
                  <h3>
                    <img src="../assets/images/icon-job-type.png" class="header-icon" />
                    Job Type/Salary
                  </h3>
                  <hr />
                  <ul>
                    <li>Full-time</li>
                    <li>Permanent</li>
                    <li>3k to 5k</li>
                  </ul>
                </div>

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-experience-sub.png"
                      class="header-icon"
                    />
                    Experience
                  </h3>
                  <hr />
                  <p>3 years and above</p>
                </div>

                <div class="listing-info">
                  <h3>
                    <img src="../assets/images/icon-education.png" class="header-icon" />
                    Education
                  </h3>
                  <hr />
                  <p>
                    Diploma & above in Information Tech, Engineering, or a related field.
                  </p>
                </div>

                <div class="listing-info">
                  <h3>
                    <img src="../assets/images/icon-location.png" class="header-icon" />
                    Location
                  </h3>
                  <hr />
                  <p>Town Area, Singapore</p>
                </div>

                <p>
                  If you meet the above qualifications and are passionate about joining
                  us, we would love to hear from you. Please apply with your resume and a
                  cover letter explaining why you are the right candidate for this
                  position.
                </p> -->

                <!-- APPLY NOW BUTTON -->
                <div class="apply">
                  <a
                    href="https://www.mycareersfuture.gov.sg/job/information-technology/devops-engineer-textedge-bd1cd83508b5a5e8dbaa37e0444f9b05"
                    target="_blank"
                  >
                    <div class="apply-btn">Apply Now!</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF ACCORDION - JOB LISTING 4 -->

          <div class="accordion-item">
            <h2
              id="headingFive"
              class="accordion-header"
            >
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
                :class="!productType ? 'collapsed' : ''"
                @click="handleClick('productType')"
              >
                Product Manager  ( Fluent in English or Chinese is required)
              </button>
            </h2>

            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#jobListingAccordion"
              :class="productType ? 'show' : ''"
            >
              <div class="accordion-body">
                <p>
                  TOC product manager
                </p>
                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-description.png"
                      class="header-icon"
                    >
                    Description
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      Responsible for the analysis and insight of C-end user needs, product design, prototype and PRD output, demand review, etc;
                    </li>
                    <li>
                      Promote the product development and testing process, and coordinate resources during the product development process;
                    </li>
                    <li>
                      Monitor the operation of the product after it is launched, and continuously iteratively optimize the product's functions and experience based on data and user feedback;
                    </li>
                  </ul>
                </div>

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-qualification.png"
                      class="header-icon"
                    >
                    Expected Qualifications & Experience
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      More than 3 years of working experience in Internet products, with rich experience in C-side projects;
                    </li>
                    <li>
                      Data-driven, with unique data analysis ideas, and able to skillfully verify hypotheses through AB testing and other methods;
                    </li>
                    <li>Have rich Internet product prototyping capabilities and complete designs such as flow charts, concept diagrams, and prototype diagrams;</li>
                    <li>
                      Familiar with the product planning process, including market analysis, demand analysis, product function design, business process design, interface design, user research and usability testing, etc;
                    </li>
                    <li>
                      Have strong communication, understanding and coordination skills, be proactive, serious and responsible for work;
                    </li>
                    <li>
                      Unified recruitment of undergraduates and above
                    </li>
                  </ul>
                </div>

                <p>
                  TOB product manager
                </p>
                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-description.png"
                      class="header-icon"
                    >
                    Description
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      Responsible for product work related to SaaS platform planning and construction;
                    </li>
                    <li>
                      The specific product scope may include functional modules or subsystems such as content management, client APP management, sales business management, user accounts, and content transactions;
                    </li>
                    <li>
                      Understand the company's strategy and product positioning, conduct competitive product research, analyze and dismantle business-side needs, determine necessity and priority, and provide corresponding solutions and functional designs;
                    </li>
                    <li>
                      Connect with UI design, development, testing, pre-sales and other departments to effectively control requirements and versions, analyze business data, promote continuous product iteration, and constantly think about long-term evolution routes and explore innovation directions.
                    </li>
                  </ul>
                </div>

                <div class="listing-info">
                  <h3>
                    <img
                      src="../assets/images/icon-qualification.png"
                      class="header-icon"
                    >
                    Expected Qualifications & Experience
                  </h3>
                  <hr>
                  <ul>
                    <li>
                      More than 3 years of working experience as a SaaS product manager in the toB field, with practical experience and understanding of the business model, business processes, multi-tenant design, permission management, etc. of SaaS platform products;
                    </li>
                    <li>
                      Have strict logical and structural thinking, be able to use systematic thinking to analyze business needs, and be proficient in using various commonly used software to complete product design efficiently;
                    </li>
                    <li>
                      Have a sense of product ownership, be willing to take responsibility, have good communication, teamwork spirit, and a certain degree of stress resistance;
                    </li>
                    <li>
                      Unified recruitment of bachelor degree or above. Applicants with a global perspective and overseas business experience will be given priority. Applicants with experience in CRM, customer service, risk control systems and other services will be given priority.               
                    </li>
                  </ul>
                </div>

                <!-- APPLY NOW BUTTON -->
                <div class="apply">
                  <a
                    href="https://www.mycareersfuture.gov.sg/job/information-technology/infrastructure-engineer-textedge-4e25bf9bcc2b692be564e87133d3d917"
                    target="_blank"
                  >
                    <div class="apply-btn">Apply Now!</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF ACCORDIONS -->
      </div>
    </div>
    <!-- END OF SUBPAGE CONTENT -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      accordionType: "infrastructure",
      infrastructureType: true,
      productType: true,
      devOpsType: false,
    };
  },
  methods: {
    handleClick(key) {
      if (key == "infrastructureType") {
        this.devOpsType = false;
        this.productType = false;
      } else if (key == "devOpsType") {
        this.infrastructureType = false;
        this.productType = false;
      } else {
        this.infrastructureType = false;
        this.devOpsType = false;
      }
      this[key] = !this[key];
    },
  },
};
</script>

<style></style>
